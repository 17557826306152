import React from "react";
import Layout from "../components/share/layout";
import Bodyportal from "../components/bodyportal"
import '../styles/font-awesome-4.7.0/css/font-awesome.css'

const IndexPage = () => (
  <div className="super_container xd">
    <Layout
      title="UPC Cultural | Universidad Peruana de Ciencias Aplicadas"
      metaTitle="UPC Cultural | Universidad Peruana de Ciencias Aplicadas"
      description="Bienvenido a UPC Cultural, un espacio de promoción, difusión y discusión del arte y la cultura que nace en la Universidad Peruana de Ciencias Aplicadas." 
      subtitile="Bienvenido a UPC Cultural, un espacio de promoción, difusión y discusión del arte y la cultura que nace en la Universidad Peruana de Ciencias Aplicadas."
      metaDescripcin="Bienvenido a UPC Cultural, un espacio de promoción, difusión y discusión del arte y la cultura que nace en la Universidad Peruana de Ciencias Aplicadas."
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      metaKeywords="upc cultural, cultural upc, centro cultural upc"
    >
      <Bodyportal></Bodyportal>
    </Layout>
  </div>
)

export default IndexPage
