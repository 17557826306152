import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Img1 from '../images/Escalera.jpg'
import ControlHomePREV from '../images/arrow_prev_red.svg'
import ControlHomeNEXT from '../images/arrow_next_red.svg'
import ControlHomeNEXT2 from '../images/arrow_next.png'
import SocialMediaContainer from '../images/social-media-container.svg'
import Image from 'gatsby-image';
import { normalize } from "../hooks/normalize";
import { Link } from 'gatsby'
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from "gatsby"
import "../styles/portal.css";

const IMGBACKGROUND = styled.div`
    background: url(${Img1}) center center no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 806px;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    z-index: -1;
`;
const Bodyportal = () => {
	const QueryResult = useStaticQuery(
		graphql`
        {
            allContentfulPortalCarruselDeImagenes(filter: {node_locale: {eq: "en-US"}}, sort: {fields: IndexDate, order: DESC}, limit: 10) {
                nodes {
                IndexTiltle
                IndexSubtitle
                IndexImage{
                  description
                  file {
                    fileName
                    url
                  }
                  fluid {
                    base64
                    aspectRatio
                    sizes
                    src
                    srcSet
                    srcSetWebp
                  }
                }
              }
            }
        }
    `)

	const QueryResultData = QueryResult.allContentfulPortalCarruselDeImagenes.nodes

	const ScrollPREV = (e) => {
		if (e.deltaY > 0) {
			if (document.querySelector('.control-prev')) {
				document.querySelector('.control-prev').click()
			}
		} else {
			if (document.querySelector('.control-next')) {
				document.querySelector('.control-next').click()
			}
		}
	}

	const clickSliderPREV = () => {
		if (document.querySelector('.control-prev')) {
			document.querySelector('.control-prev').click()
		}
	}

	const clickSliderNEXT = () => {
		if (document.querySelector('.control-next')) {
			document.querySelector('.control-next').click()
		}
	}

	return (
		<div>
			<div className="carousel_home_container" onWheel={ScrollPREV} >
				{true &&
					<button onClick={clickSliderPREV} className="button-control-prev"><img className="button-control-prev-icon" alt="icono de flecha" src={ControlHomePREV} /><p>EXHIBICIÓN ANTERIOR</p></button>
				}
				{true &&
					<button onClick={clickSliderNEXT} className="button-control-next"><p>EXHIBICIÓN SIGUIENTE</p><img alt="icono de flecha" className="button-control-next-icon" src={ControlHomeNEXT} /></button>
				}
				{true &&
					<button onClick={clickSliderPREV} className="button-control-prev button-control-prev-iconPHONE"><img alt="icono de flecha" className="button-control-prev-icon" src={ControlHomePREV} /><p>ANTERIOR</p></button>
				}
				{true &&
					<button onClick={clickSliderNEXT} className="button-control-next button-control-next-iconPHONE"><p>SIGUIENTE</p><img alt="icono de flecha" className="button-control-next-icon " src={ControlHomeNEXT} /></button>
				}
				<div className="SocialDivMediaContainer">
					<div className="SocialDivMediaSubContainer">
						<img src={SocialMediaContainer} alt="fondo de seccion compartir" />
						<div className="SocialIconSubContainer">
							<a target="_blank" aria-label="redirect" rel="noreferrer" href="https://www.facebook.com/UPC-Cultural-109744290952654">
								<div className="icon_container icon_container_1"><i className="fa fa-facebook" aria-hidden="true"></i></div>
							</a>
							<a target="_blank" aria-label="redirect" rel="noreferrer" href="https://www.instagram.com/upccultural/">
								<div className="icon_container icon_container_2"><i className="fa fa-instagram" aria-hidden="true"></i></div>
							</a>
							<a target="_blank" aria-label="redirect" rel="noreferrer" href="https://www.youtube.com/c/UPCTV_PE">
								<div className="icon_container icon_container_3"><i className="fa fa-youtube" aria-hidden="true"></i></div>
							</a>
							<a target="_blank" aria-label="redirect" rel="noreferrer" href="https://open.spotify.com/artist/6TI6DzBeAx6eDZAVfw90VE">
								<div className="icon_container icon_container_4"><i className="fa fa-spotify" aria-hidden="true"></i></div>
							</a>
						</div>
					</div>
				</div>
				<h1 className="d-none">Bienvenido a UPC Cultural</h1>
				<IMGBACKGROUND></IMGBACKGROUND>
				<div className="index-portal-carousel">
					<Carousel autoPlay infiniteLoop interval={10000} transitionTime={800} stopOnHover={false} showThumbs={false}>
						{
							QueryResultData.map((QueryResult, i) => (
								<div className="index-portal-carousel-body" key={i}>
									<div className="text-container">
										<div className="text-subcontainer">
											<h3>{QueryResult.IndexTiltle}</h3>
											<div className="horizontal-line"></div>
											<h2>{QueryResult.IndexSubtitle}</h2>
											<Link to={`/galeria/${normalize(QueryResult.IndexTiltle).toLocaleLowerCase().trim()}`} className="button-redirect-carousel">
												<p>Entrar</p>
												<div className="button-redirect-carousel-img-container">
													<img alt="icono de flecha" src={ControlHomeNEXT2} />
												</div>
											</Link>
										</div>
									</div>
									<div className="image-container">
										<Link to={`/galeria/${normalize(QueryResult.IndexTiltle).toLocaleLowerCase().trim()}`} >
											<div className="image-subcontainer">
												<div className="margin-container">
													<div className="red-margin"></div>
												</div>
												<div className="image-result-container">
													<Image fluid={QueryResult.IndexImage.fluid} alt={QueryResult.IndexImage.description ? QueryResult.IndexImage.description : QueryResult.IndexImage.file.fileName} />
												</div>
											</div>
										</Link>
									</div>
								</div>
							))
						}

						<div className="index-portal-carousel-body">
							<div className="text-container">
								<div className="text-subcontainer">
									<h3>Todas las Exhibiciones</h3>
									<div className="horizontal-line"></div>
									<h2>Ver todas</h2>
									<Link to={`/exhibiciones/`} className="button-redirect-carousel">
										<p>Entrar</p>
										<div className="button-redirect-carousel-img-container">
											<img alt="icono de flecha" src={ControlHomeNEXT2} />
										</div>
									</Link>
								</div>
							</div>
							<div className="image-container">
								<Link to={`/exhibiciones/`} >
									<div className="image-subcontainer">
										<div className="margin-container">
											<div className="red-margin"></div>
										</div>
										<div className="image-result-container">
											<img src="https://images.ctfassets.net/bv5rnjawitjg/2ZDNjSTbdGg4MT0Y3ZSXum/aa06d07fdfcbe0dce3c9c7c52a475b03/Plantilla_para_imagen_de_pagina_de_inicio.jpg" />
										</div>
									</div>
								</Link>
							</div>
						</div>

					</Carousel>
				</div>
			</div>
		</div>
	)
}

export default Bodyportal
